.Loader {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(252, 252, 252, 0.62);
    z-index: 1000000;
}

.Loader svg {
    margin: auto;
    width: 150px;
    height: 150px;
}

.spinner {
    margin: auto;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #ef745f);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    to {
        transform: rotate(1turn)
    }
}