.Single-App-Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 80vw;
    margin: auto;
}

.Single-App-Container-New {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    margin: auto;
}

.Similar-Apps-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
}

.Similar-Apps-Container h2 {
    font-size: 20px;
    text-align: left;
    margin-bottom: 10px;
}

.Similar-Apps-Container ul{
    margin: 0;
    list-style: none;
}

.Single-App-Details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 100%;
    background: #e9e9e9;
    padding: 30px;
    border-radius: 10px;
}

.Single-App-Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.Single-App-Header .Details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
}

.Single-App-Header .Rating-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    margin-top: 20px;
}

.Single-App-Header .Rating-Container div {
    display: flex;
    flex-direction: column;
    width: 130px;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
    color: #A8A8AE;
    font-weight: bolder;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
}

/*.Single-App-Header .Rating-Container div:nth-child(1) {*/
/*    border-right: 2px solid #A8A8AE;*/
/*}*/

.Single-App-Header img {
    width: 100px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin: 10px;
}

.Single-App-Header div h1 {
    font-size: 28px;
    text-align: left;
 }

.Single-App-Header div a {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding-top: 15px;
    color: #383838;
    text-decoration: none;
}

.Similar-App-Block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.Similar-App-Block span {
    margin-right: 15px;
}

.Similar-App-Block h3 {
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
}

.Similar-App-Block img {
    width: 40%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Similar-App-Block p {
    margin: 0;
    font-size: 12px;
    text-align: left;
    width: 100px;
}

.Single-App-Description {
    text-align: left;
    line-height: 1.7;
    background: white;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
}

.carousel-container{
    margin-top: 20px;
    width: 30vw;
}

.carousel-container img{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Buttons-Container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.Buttons-Container a{
    width: 50%;
    padding: 15px 0;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    font-weight: bolder;
    margin: 0 10px;
}

.Buttons-Container a img{
    width: 5%;
    margin-right: 10px;
}

.green {
    background: #4CAF50;
    color: white;
}

.blue{
    background: #196cad;
    color: white;
}

.Additional-Details-Container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 20px);
    margin-top: 20px;
    text-align: start;
    color: grey;
    background: white;
    border-radius: 10px;
    padding: 10px 10px 10px 20px;
}

.Ditail-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 200px;
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
}

.Ditail-Wrapper span:nth-child(1) {
    color: black;
}

.Ditail-Wrapper span{
    font-weight: bold;
    white-space: normal;
    overflow-wrap: break-word;
}

.Ditail-Wrapper-Link{
    font-weight: bold;
    text-decoration: none;
}

.Details-Container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    text-align: left;
}

.Advertisement-Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 85%;
    text-align: left;
    border: 1px solid black;
    color: #9c9c9c;
    background: white;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    margin: 20px 0;
}

.Advertisement-Text {
    position: absolute;
    top: -20px;
    right: 5px;
    font-size: .75rem;
}

.btn-push {
    text-decoration: none;
    background: #18a204;
    padding: 15px 35px;
    margin: 0 auto;
    border: 1px solid #18a204;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 10px;
    color: #fff;
    width: 145px;
    cursor: pointer;
    z-index: 100;
    font-size: 24px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
}

.Advertisement-Top{
    width: 50%;
}

.Advertisement-Bottom{
    width: 100%;
    height: 200px;
    margin-top: 20px;
}


.Advertisement-Nav {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80vw;
    height: 180px;
    margin-top: 5vh;
    margin-bottom: -2vh;
}

@media (max-width: 970px) {
    .Advertisement-Nav {
        /*width: 100%;*/
    }
}


@media (max-width: 1700px) {
    .Advertisement-Nav {
        padding-right: 1%;
    }
}
@media (max-width: 1650px) {
    .Advertisement-Nav {
        padding-right: 1.5%;
    }
}
@media (max-width: 1600px) {
    .Advertisement-Nav {
        padding-right: 2%;
    }
}
@media (max-width: 1550px) {
    .Advertisement-Nav {
        padding-right: 2.5%;
    }
}
@media (max-width: 1500px) {
    .Advertisement-Nav {
        padding-right: 3%;
    }
}
@media (max-width: 1450px) {
    .Advertisement-Nav {
        padding-right: 3.5%;
    }
}
@media (max-width: 1400px) {
    .Advertisement-Nav {
        padding-right: 4%;
    }
}
@media (max-width: 1350px) {
    .Advertisement-Nav {
        padding-right: 4.5%;
    }
}
@media (max-width: 1300px) {
    .Advertisement-Nav {
        padding-right: 5%;
    }
}
@media (max-width: 770px) {
    .Advertisement-Nav {
        align-items: center;
        padding-right: 0;
    }
}

.Advertisement-Container-Nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    border: 1px solid black;
    color: #9c9c9c;
    background: white;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    padding: 30px;
    border-radius: 10px;
}

.Details-Wrapper{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 3%;
}

@media (max-width: 768px) {
    .Single-App-Container {
        flex-direction: column;
        width: 90vw;
        margin: 5vh auto;
    }

    .Similar-Apps-Container {
        width: 100%;
    }

    .Single-App-Details {
        padding: 10px;
        width: 95%
    }

    .Single-App-Header {
        flex-direction: column;
        align-items: center;
    }

    .Single-App-Header .Details {
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    .Single-App-Header .Rating-Container {
        align-items: center;
    }

    .Buttons-Container {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .Buttons-Container a {
        width: 80%;
        margin: 10px 0;
    }

    .carousel-container {
        width: 90vw;
    }

    .Ditail-Wrapper {
        width: 100%;
    }

    .Details-Container {
        width: 100%;
    }

    .Advertisement-Container {
        padding: 20px;
        width: auto;
    }

    .Advertisement-Top{
        width: 100%;
        margin-top:10px;
    }

    .Advertisement-Bottom{
        width: 100%;
        height: auto;
        margin-top: 10px;
    }

    .Single-App-Header .Rating-Container div {
        width: 100%;
        margin: 0 7px;
    }

    .Single-App-Description {
        width: auto;
    }
}

