.Home-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin: auto;
}

.Home {
    display: flex;
    width: 75vw;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.App-Container:hover {
    /*box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);*/
    border-radius: 15px;
    background: #f6f6f6;
}

@media (min-width: 1050px)
.Home {
    max-width: 1290px;
}

@media (min-width: 992px)
.Home {
    max-width: 960px;
}
@media (min-width: 768px)
.Home {
    max-width: 720px;
}
@media (min-width: 576px)
.Home{
    max-width: 720px;
}
.Home {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 1200px)
.Home-xl, .Home-lg, .Home-md, .Home-sm, .Home {
    max-width: 1140px;
}
@media (min-width: 992px)
.Home-lg, .Home-md, .Home-sm, .Home {
    max-width: 960px;
}
@media (min-width: 768px)
.Home-md, .Home-sm, .Home {
    max-width: 720px;
}
@media (min-width: 576px)
.Home-sm, .Home {
    max-width: 540px;
}

.App-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vw;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1.5;
}

.App-Container h3 {
    margin-bottom: 0;
    font-size: 12px;
    text-align: left;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.App-Container img {
    width: 100px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.App-Container p {
    margin: 0;
    font-size: 13px;
    text-align: left;
    width: 110px;
}

.ant-menu {
    background-color: #6f98ad;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    justify-content: space-between;
    height: 70px;
}

.ant-menu-item {
    order: 0 !important;
}

.ant-menu::before, .ant-menu::after {
    content: none;
}

.Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35px;
    background-color: #4f6977;
    bottom: 0;
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 6px;
    padding-top: 6px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.Footer-Logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Footer-Logo p{
    margin-right: 10px;
}

.Search-Result-Container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.Search-Container span{
    color: grey;
    font-size: 20px;
    font-width: bolder;
}

.Search-Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70vw;
    margin: auto;
}

.Footer-Links {
    display: flex;
    justify-content: space-between;
    width: 200px;
    padding: 0 10px;
    text-decoration: none;
}

.Footer-Links a {
    color: white;
    text-decoration: none;
    font-weight: bolder;
}
.Footer-Links a:visited {
    color: white;
}

.Load-More {
    background-color: #99c4da;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 4px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Load-More:hover {
    background-color: #6f98ad;
}