.Privacy-Container {
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70vw;
    margin: 40px auto;
    text-align: left;
}

.Privacy-Container p {
    margin-top: 10px;
    margin-bottom: 0px;
}